import staffers from "./staffers";
import chats from "./chats";
import groups from "./groups";

export default {
  namespaced: true,
  modules: {
    chats,
    staffers,
    groups,
  },
};

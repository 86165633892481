import headTeacher from "./head-teacher";
import teacher from "./teacher";

export default {
  namespaced: true,
  modules: {
    "head-teacher": headTeacher,
    teacher,
  },
};
